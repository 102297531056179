@import url("https://fonts.googleapis.com/css2?family=Barlow:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");
/* @import url(//db.onlinewebfonts.com/c/6766d85eb675b9099cdf5b05d7333290?family=VAG+World); */
@import url("https://fonts.googleapis.com/css2?family=Barlow:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");

// telling what the font will be called
// linking to the font file
@font-face {
  font-family: VAG_World_Bold;
  src: url(./assets/Fonts/VAG_World_Bold.ttf);
}

body {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 800;
}

@font-face {
  font-family: "ProximaNova";
  font-weight: 800;
  src: local("ProximaNova"),
    url(./assets/proximaNova/ProximaNova-Bold.ttf) format("truetype");
}

@font-face {
  font-family: "ProximaNova";
  src: local("ProximaNova"),
    url(./assets/proximaNova/ProximaNova-Regular.ttf) format("truetype");
}

@font-face {
  font-family: "proxima-nova-extra-condensed";
  font-weight: 800;
  src: local("ProximaNova"),
    url(./assets/proximaNova/ProximaNovaExCn-Extrabld.ttf) format("truetype");
}

.font-link {
  font-family: "Barlow", sans-serif;
}

.font-Mont {
  font-family: "Montserrat", sans-serif;
}

::-webkit-scrollbar {
  width: 3px;
}

/* Track */
::-webkit-scrollbar-track {
  border-radius: "10px";
  box-shadow: "inset 0 0 2px #b161ce";
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #b161ce;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #b161ce;
}

.container {
  display: flex;
  align-items: center;
}

.border {
  border-bottom: 1px solid white;
  width: 100%;
}

.content {
  padding: 0 10px 0 10px;
}
